import { render, staticRenderFns } from "./handover-modal.vue?vue&type=template&id=072fde15&scoped=true"
import script from "./handover-modal.vue?vue&type=script&lang=js"
export * from "./handover-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "072fde15",
  null
  
)

export default component.exports